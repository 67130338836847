exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-company-index-js": () => import("./../../../src/pages/company/index.js" /* webpackChunkName: "component---src-pages-company-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-introduction-index-js": () => import("./../../../src/pages/introduction/index.js" /* webpackChunkName: "component---src-pages-introduction-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-recruitment-employee-js": () => import("./../../../src/pages/recruitment/employee.js" /* webpackChunkName: "component---src-pages-recruitment-employee-js" */),
  "component---src-pages-recruitment-index-js": () => import("./../../../src/pages/recruitment/index.js" /* webpackChunkName: "component---src-pages-recruitment-index-js" */),
  "component---src-pages-recruitment-partner-js": () => import("./../../../src/pages/recruitment/partner.js" /* webpackChunkName: "component---src-pages-recruitment-partner-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-offer-js": () => import("./../../../src/pages/services/offer.js" /* webpackChunkName: "component---src-pages-services-offer-js" */),
  "component---src-pages-services-works-js": () => import("./../../../src/pages/services/works.js" /* webpackChunkName: "component---src-pages-services-works-js" */),
  "component---src-pages-site-policy-index-js": () => import("./../../../src/pages/site-policy/index.js" /* webpackChunkName: "component---src-pages-site-policy-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-pages-times-columns-js": () => import("./../../../src/pages/times/columns.js" /* webpackChunkName: "component---src-pages-times-columns-js" */),
  "component---src-pages-times-index-js": () => import("./../../../src/pages/times/index.js" /* webpackChunkName: "component---src-pages-times-index-js" */),
  "component---src-pages-times-stories-js": () => import("./../../../src/pages/times/stories.js" /* webpackChunkName: "component---src-pages-times-stories-js" */),
  "component---src-pages-tools-index-js": () => import("./../../../src/pages/tools/index.js" /* webpackChunkName: "component---src-pages-tools-index-js" */),
  "component---src-pages-tools-materials-js": () => import("./../../../src/pages/tools/materials.js" /* webpackChunkName: "component---src-pages-tools-materials-js" */),
  "component---src-pages-tools-wiki-js": () => import("./../../../src/pages/tools/wiki.js" /* webpackChunkName: "component---src-pages-tools-wiki-js" */),
  "component---src-templates-columns-article-js": () => import("./../../../src/templates/columns-article.js" /* webpackChunkName: "component---src-templates-columns-article-js" */),
  "component---src-templates-company-article-js": () => import("./../../../src/templates/company-article.js" /* webpackChunkName: "component---src-templates-company-article-js" */),
  "component---src-templates-materials-article-js": () => import("./../../../src/templates/materials-article.js" /* webpackChunkName: "component---src-templates-materials-article-js" */),
  "component---src-templates-news-article-js": () => import("./../../../src/templates/news-article.js" /* webpackChunkName: "component---src-templates-news-article-js" */),
  "component---src-templates-stories-article-js": () => import("./../../../src/templates/stories-article.js" /* webpackChunkName: "component---src-templates-stories-article-js" */),
  "component---src-templates-tag-archives-js": () => import("./../../../src/templates/tag-archives.js" /* webpackChunkName: "component---src-templates-tag-archives-js" */),
  "component---src-templates-wiki-article-js": () => import("./../../../src/templates/wiki-article.js" /* webpackChunkName: "component---src-templates-wiki-article-js" */),
  "component---src-templates-works-article-js": () => import("./../../../src/templates/works-article.js" /* webpackChunkName: "component---src-templates-works-article-js" */)
}

